<template>
  <div class="container-fluid">
    <div class="page-title-box">
      <bread-crumb :breadcrumbs="[{ label: 'Больничные' }]" />
      <h4 class="page-title">Больничные</h4>
    </div>
    <GridView
      :service="service"
      :actions="[
        {
          name: 'create',
          routeName: 'SickLeaveCreate',
        },
      ]"
      :export="false"
      :columns="[
        {
          field: 'surname',
          header: 'ФИО',
          sortable: true,
          slot: 'userNameSlot',
        },
        {
          field: 'dtStart',
          header: 'Начало',
          sortable: true,
          format: 'date',
        },
        {
          field: 'dtEnd',
          header: 'Конец',
          sortable: true,
          format: 'date',
        },
        {
          actions: [
            {
              action: 'show',
              routeName: 'SickLeaveShow',
            },
            {
              action: 'edit',
              routeName: 'SickLeaveEdit',
            },
            {
              action: 'delete',
            },
          ],
        },
      ]"
      :search="[
        {
          label: 'Сотрудник',
          field: 'userId',
          type: 'multiselect',
          options: userList,
        },
        {
          label: 'Статус',
          field: 'status',
          type: 'select',
          options: [
            { id: 1, name: 'Не начался' },
            { id: 2, name: 'Активен' },
            { id: 3, name: 'Закончился' },
            { id: 4, name: 'Все' },
          ],
        },
      ]"
    >
      <template #userNameSlot="{ data }">
        <router-link
          class="text-info link_underline"
          :to="{ name: 'UserShow', params: { id: data.userId } }"
        >
          {{ data.userName.surname }} {{ data.userName.name }}
          {{ data.userName.secondName }}
        </router-link>
      </template>
    </GridView>
  </div>
</template>

<script>
import BreadCrumb from "@/components/ui/BreadCrumb";
import GridView from "@/components/ui/GridView.vue";
import SickLeaveService from "@/modules/sickLeave/services/SickLeaveService";
import UserService from "@/modules/user/services/UserService";
import Str from "@/utils/Str";

export default {
  components: {
    BreadCrumb,
    GridView,
  },
  data() {
    return {
      Str: Str,
      userList: {},
      service: new SickLeaveService(),
      userService: new UserService(),
    };
  },
  async created() {
    this.userList = await this.userService.allList();
  },
};
</script>
